import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

function Page() {
  return (
    <Layout>
      <SEO title="User Onboarding Done for You" />

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <g
                id="Artboard-Copy-2"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="wave"
                  transform="translate(303.500000, 334.000000) rotate(-90.000000) translate(-303.500000, -334.000000) translate(-30.000000, 31.000000)"
                  stroke="#e5e7eb"
                  stroke-width="0.4898"
                >
                  <g id="Group">
                    <path
                      d="M568.6,439.8 C646.4,327.2 764.7,-37.8 520.2,3.7 C299.3,41.2 242,449.2 0.6,409.2"
                      id="Path"
                    ></path>
                    <path
                      d="M553.8,454.9 C588.1,404.5 628.9,304.1 639.9,214.2 C647.3,154.5 641.6,99.3 612,67.4 C595.3,49.3 571,38.8 537.6,38.5 C526.5,38.4 514.5,39.5 501.4,41.8 C410,57.8 347.7,140.1 287.1,223.9 C253.5,270.4 220.3,317.3 183.3,354.2 C134,403.5 77.3,434.8 4,420.7"
                      id="Path"
                    ></path>
                    <path
                      d="M539,469.9 C571.7,421.2 608.5,326.1 617.2,241.1 C622.9,184.9 616.3,132.9 587.7,103.1 C571.4,86.2 548.2,76.6 516.8,76.6 C506.2,76.6 494.8,77.7 482.5,79.9 C395.9,95.6 336.4,173.5 278.2,251.8 C245.8,295.3 213.5,338.8 177.7,373.2 C130.4,418.7 75.7,447.6 7.2,432.2"
                      id="Path"
                    ></path>
                    <path
                      d="M524.3,485 C555.5,437.9 588.4,348.2 594.6,268.1 C598.7,215.4 591.2,166.6 563.4,138.9 C547.5,123.2 525.4,114.4 496,114.7 C486,114.8 475.2,115.9 463.6,118.1 C381.8,133.5 325.2,207 269.3,279.8 C238.1,320.3 206.7,360.5 172.2,392.3 C126.8,434 74.2,460.4 10.5,443.8"
                      id="Path"
                    ></path>
                    <path
                      d="M509.5,500.1 C539.1,454.6 568.1,370.3 571.9,295 C574.4,245.8 566,200.1 539.1,174.7 C523.6,160.1 502.6,152.4 475.3,152.8 C465.8,153 455.6,154.1 444.8,156.2 C367.9,171.3 314.1,240.5 260.6,307.7 C230.5,345.3 200.1,382.1 166.9,411.2 C123.5,449.2 73,473 14,455.3"
                      id="Path"
                    ></path>
                    <path
                      d="M494.7,515.2 C522.7,471.3 547.9,392.4 549.2,322 C550.1,276.3 540.8,233.7 514.7,210.5 C499.7,197.1 479.6,190.3 454.4,190.9 C445.4,191.1 435.9,192.3 425.8,194.4 C353.7,209.2 302.7,274 251.6,335.7 C222.7,370.3 193.3,403.8 161.3,430.3 C119.9,464.5 71.4,485.9 17.2,466.9"
                      id="Path"
                    ></path>
                    <path
                      d="M480,530.2 C506.4,487.9 527.8,414.4 526.6,348.9 C525.9,306.7 515.7,267.3 490.5,246.2 C475.9,234 456.9,228.1 433.7,228.9 C425.2,229.2 416.3,230.4 406.9,232.4 C339.6,246.9 291.5,307.3 242.7,363.5 C215,395.1 186.5,425.3 155.7,449.1 C116.4,479.6 69.8,498.4 20.4,478.2"
                      id="Path"
                    ></path>
                    <path
                      d="M465.2,545.3 C490,504.7 507.6,436.5 503.9,375.8 C501.6,337.2 490.4,300.8 466.1,281.9 C451.9,270.8 434,266 412.9,267 C404.9,267.4 396.6,268.6 388,270.5 C325.5,284.7 280.4,340.8 233.8,391.4 C207.4,420.1 179.8,446.9 150.2,468.1 C112.9,494.9 68.4,511.1 23.7,489.8"
                      id="Path"
                    ></path>
                    <path
                      d="M450.4,560.4 C473.6,521.4 487.4,458.6 481.2,402.8 C477.3,367.7 465.2,334.5 441.8,317.7 C428,307.8 411.1,303.9 392.1,305.1 C384.6,305.6 377,306.8 369.1,308.7 C311.5,322.6 269.1,374.3 224.9,419.4 C199.7,445.1 173,468.6 144.7,487.1 C109.5,510.1 66.9,523.9 27,501.3"
                      id="Path"
                    ></path>
                    <path
                      d="M435.6,575.5 C457.3,538.1 467.3,480.7 458.5,429.8 C453.1,398.2 440,368.2 417.4,353.6 C404,344.9 388.2,342 371.2,343.3 C364.2,343.9 357.2,345.1 350.1,346.9 C297.3,360.5 257.7,407.7 215.9,447.4 C191.8,470.1 166.2,490.3 139,506.2 C105.8,525.6 65.2,536.7 30.1,512.9"
                      id="Path"
                    ></path>
                    <path
                      d="M420.9,590.5 C441,554.7 447.3,502.7 436,456.6 C429.1,428.5 414.9,401.7 393.3,389.2 C380.3,381.7 365.6,379.7 350.7,381.2 C344.2,381.8 337.9,383.1 331.5,384.9 C283.5,398.2 246.8,441 207.3,475.2 C184.4,494.9 159.8,511.9 133.8,525 C102.7,540.7 64.1,549.2 33.7,524.3"
                      id="Path"
                    ></path>
                    <path
                      d="M406.1,605.6 C424.6,571.4 427.3,524.8 413.3,483.6 C404.9,459 389.7,435.4 369,425 C356.4,418.7 342.8,417.7 329.9,419.4 C278.2,426 240.4,470.8 198.4,503.2 C176.7,520 153.1,533.7 128.3,544.1 C99.3,556.2 62.6,562.1 37,535.9"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <g
                id="wave-left"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="wave" stroke="#e5e7eb" stroke-width="0.4898">
                  <g id="Group">
                    <path
                      d="M174,727.428073 C300.257453,856.224236 743.41188,1100.8974 750.931916,774.227088 C757.7923,478.800065 243.659285,302.413918 356.064039,0"
                      id="Path"
                    ></path>
                    <path
                      d="M158,705.202479 C214.590402,762.297299 334.498737,840.225794 448.075279,877.014373 C523.529148,901.540093 596.344771,908.001169 645.152344,877.673667 C672.722027,860.532035 692.508881,831.786837 701.215096,788.537182 C704.117168,774.164583 705.700117,758.341538 705.963941,740.80433 C708.074539,618.439451 617.186923,517.171965 523.924885,417.7505 C472.21524,362.501702 419.714121,307.912198 381.195712,250.68552 C329.749892,174.603046 303.36742,93.2462246 340.039056,2"
                      id="Path"
                    ></path>
                    <path
                      d="M143,682.041422 C197.852073,736.628441 311.77561,808.092606 419.633774,840.660224 C490.96784,862.152214 559.9285,866.635206 605.682513,836.968348 C631.658134,820.091202 649.854255,792.533987 657.765611,751.791502 C660.40273,738.07882 661.853146,723.047612 661.985002,706.566024 C663.303561,590.535646 577.46534,494.019467 490.704128,399.08552 C442.576708,346.212586 394.317432,293.471505 358.716327,238.620781 C311.77561,165.838088 288.04154,87.7812882 325.093061,3"
                      id="Path"
                    ></path>
                    <path
                      d="M127,658.769195 C180.125899,710.860601 287.95961,776.007828 390.124801,804.097625 C457.356088,822.560401 522.346332,825.197941 565.057973,796.185006 C589.313966,779.568507 606.187701,753.061234 613.306308,715.080665 C615.679177,702.024844 616.997437,687.78213 616.997437,672.220647 C617.524742,562.499001 536.583545,470.712625 456.433305,380.113141 C411.8761,329.604258 367.714372,278.831622 335.285163,226.212708 C292.705348,157.109171 271.745006,82.3349248 309.183605,4"
                      id="Path"
                    ></path>
                    <path
                      d="M111,636.242329 C162.493486,685.939329 264.556217,744.600244 361.20553,768.328255 C424.318059,783.883284 485.714139,784.542396 525.456548,756.068783 C548.166496,739.72282 563.614542,714.412942 569.820167,678.952748 C572.064755,666.561454 573.121032,653.115581 572.988997,638.61513 C572.724928,535.266461 496.540975,448.263755 422.865679,362.183805 C381.67089,313.805028 341.664412,265.294428 312.220701,214.938316 C273.930672,149.29082 255.7099,77.8431435 293.603825,6"
                      id="Path"
                    ></path>
                    <path
                      d="M95,613.419002 C144.898991,660.64502 240.868743,713.147687 331.690186,732.40746 C390.697723,744.939503 448.253172,743.620341 484.819364,715.786012 C505.94063,699.69223 519.801461,675.41964 525.345793,642.572493 C527.325912,630.831947 528.117959,618.167987 527.985951,604.580613 C526.929888,507.490253 455.777623,425.306429 388.585596,343.518354 C350.963341,297.479583 315.057189,250.913147 288.655607,202.763715 C254.861581,140.631161 239.416656,72.4304601 277.566942,7"
                      id="Path"
                    ></path>
                    <path
                      d="M79,590.267701 C127.151372,635.128571 216.98996,681.176934 302.211293,696.086576 C356.958744,705.718469 410.650822,702.419875 444.290822,675.107522 C463.815214,659.274274 476.083919,636.184121 480.833096,605.969005 C482.548076,594.885732 483.207684,583.010796 482.943841,570.476141 C480.965018,479.698851 414.872312,402.247879 354.320312,324.796907 C320.416469,280.991587 288.49145,236.526548 265.405175,190.610129 C235.722823,132.027111 223.058352,66.9788494 261.57945,8"
                      id="Path"
                    ></path>
                    <path
                      d="M64,567.762781 C110.389109,610.109482 194.074008,649.949681 273.541715,660.371455 C324.148016,667.099435 373.963594,661.690667 404.538234,635.042587 C422.461299,619.475887 433.267853,597.445048 437.22147,569.87352 C438.67113,559.451746 439.198279,548.370367 438.934704,536.761302 C436.167172,452.331742 375.017892,379.775089 321.11691,306.690751 C290.542269,265.267498 262.866949,222.788876 242.835288,179.122962 C217.532137,124.243748 207.648094,62.5046327 246.52533,10"
                      id="Path"
                    ></path>
                    <path
                      d="M47,544.636637 C91.7737112,584.477988 169.698459,618.118864 243.660932,624.055489 C290.151629,627.881314 336.246099,620.493514 363.84995,594.372363 C380.095279,578.937138 389.472664,558.092988 392.642484,533.159162 C393.963243,523.396712 394.227395,513.106562 393.831167,502.552561 C390.265119,424.45296 333.868734,356.643508 286.453506,288.042506 C259.510033,248.86078 235.736381,208.49173 218.830673,167.067279 C197.830614,115.616527 190.698519,57.0418261 229.925044,11"
                      id="Path"
                    ></path>
                    <path
                      d="M32,521.772387 C75.0106762,559.205381 146.783093,586.489289 214.993276,587.939158 C257.344279,588.861802 299.431413,579.371747 324.103182,553.933128 C338.747921,538.775402 346.532062,519.13626 349.038819,496.729186 C350.094296,487.63455 350.22623,478.144495 349.698492,468.522634 C345.344651,396.819997 294.15403,333.684771 253.122372,269.626901 C229.76995,232.721132 209.979762,194.629106 196.258564,155.48263 C179.502871,107.768742 175.280964,52.5418953 214.861341,13"
                      id="Path"
                    ></path>
                    <path
                      d="M16,498.671885 C57.254956,533.621014 122.893832,554.854259 185.501193,551.689055 C223.59283,549.710802 261.816271,538.105054 283.432287,513.310955 C296.349174,498.408118 302.675812,479.812544 304.389277,460.161902 C305.180107,451.589474 305.180107,443.017046 304.521082,434.444618 C299.380688,369.030399 253.117143,310.737889 218.847851,250.994661 C199.077105,216.309299 183.260509,180.305101 172.716111,143.37772 C160.194639,99.196745 158.876589,46.9708766 198.813495,14"
                      id="Path"
                    ></path>
                    <path
                      d="M0,476.151912 C39.6216315,508.568112 99.4502952,523.8538 156.373372,515.94741 C190.447976,511.203575 224.786723,497.499166 243.540962,473.252902 C254.899163,458.494307 259.521687,441.100249 260.710336,424.10151 C265.200787,355.579461 216.46618,295.359122 185.033019,233.030412 C168.78815,200.745985 156.901661,166.748507 149.637695,132.092163 C141.18508,91.5060268 142.902018,42.6181805 183.18401,16"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-oOrange font-semibold tracking-wide uppercase">
                Onboarding As A Service
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Done for You by Nickelled
              </span>
            </h1>
            <p className="my-12 text-xl text-gray-500 leading-8">
              If you're a SaaS business struggling to get your new users
              started, Onboarding as a Service is the perfect solution for you.
              It's powered by Nickelled, the company that brought you the
              world's easiest onboarding software, and it's available now.
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto max-w-4xl">
            <p>
              Onboarding as a Service is designed to help you get your new users
              up to speed quickly, so they can start doing the things they're
              meant to be doing.
            </p>
            <p>
              After placing a single line of Javascript code on your site, you
              can work alongside our onboarding experts to design customized,
              immersive onboarding experiences without making any changes to
              your app.
            </p>
            <p>
              For instance, if your SaaS company requires a specific API
              integration to be present before users can use your app, your
              onboarding flow will include this step.
            </p>
            <p>
              If you require users to get javascript installed, we'll configure
              your custom onboarding experience to include that.
            </p>
            <h2 class="py-4">How does it work?</h2>
            <p>
              Nickelled's powerful platform is so versatile that you can specify
              almost any requirement and adjust your first-run experience
              accordingly, so that your users feel like you're walking through
              the process with them.
            </p>
            <blockquote class="pl-4 py-2 border-l-2 border-oOrange text-gray-700 text-xl">
              <p>
                "One of the biggest challenges for SaaS businesses is getting
                their new users started," explains Nickelled CEO David Batey.
                "That's why we created Onboarding As A Service. It's a great way
                to ensure your users are getting the most out of your product as
                quickly as possible."{" "}
              </p>
            </blockquote>
            <p></p>
            <p>
              Onboarding as a Service is powered by Nickelled's revolutionary
              new Flows onboarding API, which allows anybody to build custom,
              interactive onboarding experiences in minutes.
            </p>
            <p>
              Onboarding as a Service is an affordable way to ensure your new
              users are getting started quickly. It includes one or more of the
              following products:
            </p>
            <figure>
              <StaticImage
                className="w-full rounded-lg"
                src="../images/dfy/flows-screenshot.png"
                alt=""
                width={1310}
                height={873}
              />
              <figcaption>The Nickelled Flows onboarding API.</figcaption>
            </figure>
            <h2 class="py-4">What does it include?</h2>
            <ul class="list-disc ml-4">
              <li>
                A consultation with a Nickelled onboarding expert to determine
                which customer activation events you should track
              </li>
              <li>Embedding of a welcome video for new users</li>
              <li>
                A wizard-style interface to guide users through more complex
                setup steps
              </li>
              <li>
                Access to the Nickelled API, to track when users have completed
                key activation events and progress them through the wizard
                accordingly
              </li>
              <li>
                A checklist displayed on screen to push users through multiple
                activation events
              </li>
              <li>
                A customised walkthrough to help users get started with the most
                important features
              </li>
            </ul>
            <p>
              Onboarding as a Service is most useful for businesses which
              require significant effort from their users before the users can
              see value from the product.
            </p>
            <p>
              For example, in a B2B SaaS app, a key customer activation event is
              often to invite multiple colleagues on the team to use the
              product. A robust onboarding strategy, powered by Onboarding as a
              Service, will ensure that new users have done this before they go
              any further.
            </p>
            <p>
              B2C apps often have complex registration processes, or users may
              need to understand the product before they can get started using
              it. Onboarding as a Service can help them through this process
              too.
            </p>
            <p>
              In the long term, better onboarding reduces churn and increases
              conversion rates and customer lifetime value.
            </p>
            <p>
              The best way to get started with Onboarding as a Service is to get
              in touch with Nickelled. They'll be able to walk you through the
              onboarding process and get you set up with your own custom
              onboarding experience.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full bg-oOrange-light rounded-md p-8 mb-8">
        <div className="grid grid-cols-2 items-center  mx-auto max-w-4xl">
          <div>
            <h3 className="text-oOrange text-5xl font-bold pt-0 my-0">
              Find out more
            </h3>
            <p className="text-oOrange mt-4 mb-0">
              Book a time to speak with an onboarding expert.
            </p>
          </div>
          <div class="text-center">
            <OutboundLink
              href="/request-demo/"
              class="inline-flex px-8 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-oOrange hover:bg-oOrange-dark hover:text-white"
            >
              Book a Demo
            </OutboundLink>
          </div>
        </div>
      </div>
      <section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <title id="svg-workcation"></title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            />
          </svg>

          <div class="relative">
            <StaticImage
              src="../images/index/gengo-logo.png"
              class="mx-auto h-8 w-full"
              alt="Gengo Logo"
              objectFit="contain"
            />
            <blockquote class="mt-10">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>
                  &ldquo;Nickelled was the only company that we found where we
                  could create tutorials to our liking without needing to add
                  HTML code to our website.&rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="hidden md:flex md:flex-shrink-0">
                    <StaticImage
                      src="../images/index/lara-fernandez.png"
                      class="mx-auto h-10 w-10 rounded-full"
                      alt="Lara Fernandez, Community Manager Gengo"
                    />
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base font-medium text-gray-900">
                      Lara Fernandez
                    </div>
                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      Community Manager, Gengo
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Page
